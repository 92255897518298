import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SkipLink = ({ targetId, textId, defaultText, classNames }) => {
    const { t } = useTranslation();
    const text = t(textId, {
        defaultValue: defaultText,
    });

    return (
        <a
            href={targetId}
            className={classnames('skip-link', classNames)}
            aria-label={text}>
            {text}
        </a>
    );
};

SkipLink.propTypes = {
    targetId: PropTypes.string.isRequired,
    text: PropTypes.string,
    defaultText: PropTypes.string,
    classNames: PropTypes.string,
};

SkipLink.defaultProps = {
    targetId: '',
    textId: 'navigation.skipToContent',
    defaultText: 'Skip to Main Content',
    classNames: '',
};

export default SkipLink;
