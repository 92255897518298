import React from 'react';
import {
    Placeholder,
    VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import ConditionalStaticPlaceholder from './ConditionalStaticPlaceholder';
import SkipLink from './components-common/SkipLink';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, context }) => {
    let redirectDictionary;
    const { t } = useTranslation();
    const canonicalUrl = context?.canonicalDataLayer?.canonicalUrl;

    try {
        redirectDictionary = JSON.parse(
            t('redirects.gich', { defaultValue: '[]' })
        );

        redirectDictionary.forEach((val) => {
            if (window.location.pathname === val.pathName) {
                window.location.replace(val.extRedirect);
            }
        });
    } catch (err) {
        redirectDictionary = [];
        console.error("Fix the 'redirects.gich' dictionary item");
    }

    return (
        <React.Fragment>
            {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
            <Helmet>
                {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
                <title>
                    {(route.fields &&
                        route.fields.pageTitle &&
                        route.fields.pageTitle.value) ||
                        'Page'}
                </title>
            </Helmet>

            {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
        */}
            <VisitorIdentification />

            {/* root placeholder for the app, which we add components to using route data */}
            <div className="site">
                <header className="site__header">
                    <SkipLink targetId="#mainContent" />
                    {/*
                Rendering for primary nav is in sitecore context and following this technique
                https://jss.sitecore.com/docs/techniques/extending-layout-service/layoutservice-static-context-rendering
                */}
                    <ConditionalStaticPlaceholder
                        name="jss-nav"
                        contextKey="navigation.sitecore.route"
                    />
                </header>
                <div className="site__body">
                    <main id="mainContent" tabIndex="-1" role="main">
                        <ConditionalStaticPlaceholder
                            name="jss-banner"
                            contextKey="covidBanner.sitecore.route"
                        />
                        <Placeholder name="jss-main" rendering={route} />
                    </main>
                    {/*
                Rendering for footer is in sitecore context and following this technique
                https://jss.sitecore.com/docs/techniques/extending-layout-service/layoutservice-static-context-rendering
                */}
                    <ConditionalStaticPlaceholder
                        name="jss-footer"
                        contextKey="navigation.sitecore.route"
                    />
                </div>
            </div>

            {/* Modals will be mounted here via react portal to facilitate easier focus management */}
            <div className="site-modals"></div>
        </React.Fragment>
    );
};

export default Layout;
